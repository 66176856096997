<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-07-15 10:31:40
-->
<template>
	<div class="recruitDetail">
		<common-title :title="'招募详情'" />
		<div class="recruitDetail-content">
			<!-- 活动详情 -->
			<div class="">
				<div class="common-table">
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">招募名称</span>
							<el-input readonly="readonly" v-model="recruitDetail.recruitName" placeholder="请输入"
								class="common-table-val" />
						</div>
						<div class="common-table-col">
							<span class="common-table-label">招募状态</span>
							<span
								class="common-table-text">{{recruitDetail.status == 0? "未开始":recruitDetail.status == 1? "进行中":"已完成"}}</span>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">招募校区</span>
							<el-input readonly="readonly" v-model="recruitDetail.ccampus" placeholder="请输入"
								class="common-table-val" />
						</div>
						<div class="common-table-col">
							<span class="common-table-label">组织方</span>
							<el-input readonly="readonly" v-model="recruitDetail.organizer" placeholder="请输入"
								class="common-table-val" />
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动名称</span>
							<el-input readonly="readonly" v-model="recruitDetail.activityName" placeholder="请输入"
								class="common-table-val" />
						</div>
						<div class="common-table-col">
							<span class="common-table-label">需求人数</span>
							<el-input readonly="readonly" v-model="recruitDetail.peopleNum" placeholder="请输入"
								class="common-table-val" />
						</div>
					</div>
					<div class="">
						<div v-if="recruitDetail.recruitTimes&&recruitDetail.recruitTimes.length > 0"
							class="common-table-row">
							<div class="common-table-col">
								<span class="common-table-label">活动时间</span>
								<div class="common-table-text">
									<div class="">
										<span v-for="(item, index) in recruitDetail.recruitTimes" :key="index"
											class="common-table-date">
											<span v-if="item.timeStr">{{item.timeStr}}</span>
											<span v-else>
												{{item.starTime}} 至
											{{item.endTime}}<span
												v-if="index != recruitDetail.recruitTimes.length - 1">
												,
											</span>
											</span>
											
										</span>
									</div>
								</div>
							</div>
							<div class="common-table-col">
								<span class="common-table-label">活动地址</span>
								<el-input readonly="readonly" v-model="recruitDetail.recruitAddress" placeholder="请输入"
									class="common-table-val" />
							</div>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动详情</span>
							<quill-editor :options="editorOption" class="common-table-val" :style="{'height': '400px'}"
								v-model="recruitDetail.activityDetails" @focus="editorFocus($event)">
							</quill-editor>
							<!-- <el-input type="textarea" v-model="recruitDetail.plan" placeholder="请输入"
								class="common-table-val" /> -->
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">定时招募</span>
							<span
								class="common-table-text">{{recruitDetail.timingRecruitTime?recruitDetail.timingRecruitTime:'无'}}</span>
							<!-- <el-date-picker class="common-table-val" v-model="recruitDetail.date" type="date"
								range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker> -->
						</div>
						<div class="common-table-col">
							<span class="common-table-label">定向招募</span>
							<div v-if="recruitDetail.colleges&&recruitDetail.colleges.length > 0"
								class="common-table-text">
								<span v-for="(item, index) in recruitDetail.colleges" :key="index">{{item.name}},</span>
							</div>
							<div v-else class="common-table-text">
								<span>全部学院</span>
							</div>
						</div>
					</div>
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">联系方式</span>
							<el-input readonly="readonly" v-model="recruitDetail.contact" placeholder="请输入"
								class="common-table-val" />
						</div>
					</div>
				</div>
				<div class="recruitDetail-btn">
					<el-button @click="editRecruit" type="primary">修改活动招募</el-button>
					<el-button @click="changeRecruitFn('close')" type="primary">关闭招募</el-button>
					<el-button @click="changeRecruitFn('open')" type="primary">开启招募</el-button>
				</div>
			</div>
			<div class="recruitDetail-apply" v-if="recruitDetail.recruitTimeAddress&&recruitDetail.recruitTimeAddress.length>0">
				<el-row :gutter="24">
					<el-col v-for="(item, index) in recruitDetail.recruitTimeAddress" :key="index" :xs="24" :sm="24"
						:md="12" :lg="12" :xl="12">
						<el-table :data="item" border style="width: 100%">
							<el-table-column prop="starTime" label="活动时间" >
								<template slot-scope="scope">
									<span v-if="scope.row.timeStr">{{scope.row.timeStr}}</span>
									<span v-else>{{scope.row.starTime}}~{{scope.row.endTime}}</span>
								</template>
							</el-table-column>
							<el-table-column prop="address" label="活动地点">
							</el-table-column>
							<el-table-column width="100px" prop="signUpNum" label="报名人数">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
			</div>
			<!-- 人员列表 -->
			<div class="">
				<div class="recruitDetail-title">
					报名信息
				</div>
				<div class="recruitDetail-btn flex-between">
					<div class="flex-item">
						<el-button @click="senDmessagePeo(2)" type="primary">录取人员选择</el-button>
						<el-button @click="senDmessagePeo(4)" type="primary">不录取人员选择</el-button>
						<el-button @click="senDmessagePeo(3)" type="primary">替补人员选择</el-button>
						<el-button v-if="recruitDetail.button" @click="toTimeSheet">生成工时表</el-button>
						<span v-else class="recruitDetail-btn-disable">生成工时表</span>
					</div>
					<div class="flex-item">
						<div class="recruitDetail-select">
							<div @click="memberTypeFn('show')" class="recruitDetail-select-val">
								<span>{{memberTypeMap(memberType)}}</span>
								<img :class="{'select-option-show':memberTypeShow}"
									src="@/assets/images/common/select_down.png">
							</div>
							<div v-if="memberTypeShow" class="recruitDetail-select-option">
								<div @click="memberTypeFn('select', item)" v-for="(item,index) in memberTypeList"
									:key="index" class="flex-item">
									<span :class="{'select-option-select': memberType == item.type}">{{item.val}}</span>
									<img v-if="memberType == item.type" src="@/assets/images/common/gou.png">
								</div>
							</div>
						</div>
						<el-button @click="downLoadApply">导出报名信息</el-button>
					</div>
				</div>
				<div class="recruitDetail-table">
					<el-table style="width: 100%" :data="memberData" border>
						<el-table-column v-if="sendMessageType" width="60px" prop="" label="选择">
							<template slot-scope="scope">
								<div class="">
									<el-checkbox :disabled="scope.row.disable" @change="applyChecked($event, scope)"
										v-model="scope.row.checked" />
								</div>
							</template>
						</el-table-column>
             <el-table-column type="index" label="序号" width="80">
                <template slot-scope="scope">
								<span>{{(pageIndex-1)*pageSize + (scope.$index+1)}}</span>
							</template>
						</el-table-column>
						<el-table-column width="100px" prop="regNum" label="注册号">
							<template slot-scope="scope">
								<span>{{scope.row.volunteer.regNum}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="name" label="姓名">
							<template slot-scope="scope">
								<span>{{scope.row.volunteer.name}}</span>
							</template>
						</el-table-column>
            <el-table-column width="120px" prop="studentId" label="学号">
							<template slot-scope="scope">
								<span>{{scope.row.volunteer.studentId}}</span>
							</template>
						</el-table-column>
            <el-table-column width="250px" prop="activityTime" label="所选时间">
							<template slot-scope="scope">
								<div class="flex-column">
									<span v-for="(item, index) in scope.row.timeAddress"
										:key="index">
											<span v-if="item.timeStr"></span>
											<span v-else>{{dateFormatNew(item.timeStar)}}~{{dateFormatNew(item.timeEnd)}}</span>
										</span>
								</div>
							</template>
						</el-table-column>
            <el-table-column width="200px" prop="remark" label="备注">
						</el-table-column>
						<el-table-column prop="name" label="信誉分">
							<template slot-scope="scope">
								<span>{{scope.row.creditScore}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="gender" label="性别">
							<template slot-scope="scope">
								<span>{{scope.row.volunteer.gender == true?"男":scope.row.volunteer.gender == false?"女":''}}</span>
							</template>
						</el-table-column>
						<el-table-column width="180px" prop="collegeName" label="学院班级">
							<template slot-scope="scope">
								<span>{{scope.row.volunteer.collegeName}}{{scope.row.volunteer.faculty}}</span>
							</template>
						</el-table-column>
						<el-table-column width="180px" prop="phone" label="联系方式">
							<template slot-scope="scope">
								<span>{{scope.row.volunteer.phone}}</span>
							</template>
						</el-table-column>
						<el-table-column width="240px" prop="activityAddress" label="所选地点">
							<template slot-scope="scope">
								<div class="flex-column">
									<span>{{scope.row.address}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="state" label="录取结果">
							<template slot-scope="scope">
								<span>{{scope.row.state == 1?"报名中":scope.row.state == 2?"录取":scope.row.state == 3?"替补":scope.row.state == 4?"未录取":scope.row.state == 5?"工时已生成":""}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="state" label="是否为领队">
							<template slot-scope="scope">
								<span>{{scope.row.activityLeader?"是":"否"}}</span>
							</template>
						</el-table-column>
						<el-table-column width="110px" prop="noticeState" label="发送状态">
							<template slot-scope="scope">
								<div class="table-action">
									<span
										:class="`table-action-status${scope.row.noticeState}`">{{statusMap(scope.row.noticeState)}}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column width="160px" prop="sendTime" label="发送时间">
						</el-table-column>
            <el-table-column width="160px" prop="createTime" label="报名时间">
							<template slot-scope="scope">
								<span>{{scope.row.createTime}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="action" label="操作" fixed="right" width="150">
							<template slot-scope="scope">
								<div class="table-action">
									<span @click="actionFn('submit', scope)">私聊</span>
									<el-divider direction="vertical"
										v-if="(scope.row.state == 2 || scope.row.state == 5)"></el-divider>
									<span
										v-if="scope.row.state == 2 || scope.row.state == 5"
										@click="actionFn('leader', scope)">设为领队</span>
									<!-- <span
										v-if="scope.row.activityLeader&&(scope.row.state == 2 || scope.row.state == 5)"
										@click="actionFn('leader', scope)">取消领队</span> -->
									<!-- <span v-if="!scope.row.activityLeader&&scope.row.state == 2&&!recruitDetail.leader"
										@click="actionFn('leader', scope)">设为领队</span> -->
								</div>
							</template>
						</el-table-column>
					</el-table>
          <div class="common-pageNation">
            <el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
              :page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
            </el-pagination>
          </div>
				</div>
				<div v-if="sendMessageType" class="recruitDetail-inform flex-item">
					<div class="all-select" @click="handleSelect">
						<img  src="@/assets/images/common/select_icon.png" v-if="showAllSelect" class="select-icon">
						<img  src="@/assets/images/common/unselect_icon.png" v-if="!showAllSelect" class="select-icon">
						<span>全选</span>
					</div>
					<el-button @click="sendMessageFn" type="primary">
						{{sendMessageType == 2?'录取通知发送':sendMessageType == 3?'替补通知发送':sendMessageType == 4?'不录取通知发送':''}}
            <span v-if="number>0">（{{number}}）</span>
					</el-button>
					<el-button @click="resetMessageFn">重置</el-button>
				</div>
			</div>
			<el-dialog @close="closeLeaderDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
				:visible.sync="leaderDialogVisible" width="520px">
				<div slot="title" class="dialog-header">
					<span>{{dialogLeaderTitle}}</span>
					<img @click="closeLeaderDialog" src="@/assets/images/common/close.png">
				</div>
				<el-form :model="leaderForm" :rules="leaderRules" ref="leaderForm" label-width="40px" class="demo-ruleForm">
					<el-form-item  prop="timeIds" v-if="timeAddress&&timeAddress.length>0">
						<el-checkbox-group v-model="leaderForm.timeIds">
							<el-checkbox 
							v-for="item in timeAddress" 
							:key="item.id" 
							:label="item.id"
							:disabled="!item.canSelect"
							  >{{item.starTime}}~{{item.endTime}}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="closeLeaderDialog">取 消</el-button>
					<el-button @click="submitLeaderDialog" type="primary">确 定</el-button>
				</span>
			</el-dialog>
			<el-dialog @close="closeDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
				:visible.sync="dialogVisible" width="520px">
				<div slot="title" class="dialog-header">
					<span>{{dialogTitle}}</span>
					<img @click="closeDialog" src="@/assets/images/common/close.png">
				</div>
				<el-form v-if="dialogTitle == '录取通知发送' || dialogTitle == '替补通知发送'" :model="sendMessage"
					:rules="sendMessageRules" ref="messegsForm" label-width="100px" class="">
					<el-form-item class="recruitDetail-dia-formitem" label="群号:" prop="qqGroupNumber">
						<div class="recruitDetail-dia-ipt">
							<el-input v-model="sendMessage.qqGroupNumber" 
							placeholder="请输入"></el-input>
						</div>
					</el-form-item>
					<el-form-item class="recruitDetail-dia-formitem" label="活动负责人:" prop="contact">
						<div class="recruitDetail-dia-ipt">
							<el-input v-model="sendMessage.contact" placeholder="请输入"></el-input>
						</div>
					</el-form-item>
					<el-form-item v-if="dialogTitle == '录取通知发送'" class="recruitDetail-dia-formitem" label="录取时间:" prop="admissionTimes">
						<el-checkbox-group v-model="sendMessage.admissionTimes">
							<el-checkbox v-for="item in admissionTimesData" :label="item.label" :key="item.value">{{item.label}}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</el-form>
				<el-form v-else :model="messegsForm" :rules="messegsRules" ref="messegsForm" label-width="100px"
					class="">
					<el-form-item class="recruitDetail-dia-formitem" label="通知标题:" prop="title">
						<div class="recruitDetail-dia-ipt">
							<el-input v-model="messegsForm.title" placeholder="请输入"></el-input>
						</div>
					</el-form-item>
					<el-form-item class="recruitDetail-dia-formitem" label="通知内容:" prop="content">
						<div class="recruitDetail-dia-textarea">
							<el-input type="textarea" v-model="messegsForm.content" placeholder="请输入"></el-input>
						</div>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="closeDialog">取 消</el-button>
					<el-button :loading="btnLoading" @click="submitDialog" type="primary">确 定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
import {
  detailRecruit,
  changeRecruit,
  listRecruitApply,
  sendApplyedMessage,
  downloadApplyList,
  editApplyed,
  chatPrivate,
  signUpInformation,
  listTimesLeader
} from "@/api/recruit";
export default {
  data() {
    return {
      totalCount: 0,
      pageIndex: 1,
      pageSize: 50,
      recruitDetail: {},
      statusList: [],
      memberData: [],
      memberType: 1,
      memberTypeShow: false,
      memberTypeList: [
        {
          val: "按报名时间排序",
          type: 1
        },
        {
          val: "按姓名字母排序",
          type: 2
        },
        {
          val: "按信誉分排序",
          type: 3
        }
      ],
      dialogVisible: false,
      dialogTitle: "",
      messegsRules: {
        title: [
          {
            required: true,
            message: "请输入通知标题",
            trigger: "blur"
          }
        ],
        content: [
          {
            required: true,
            message: "请输入通知内容",
            trigger: "blur"
          }
        ]
      },
      messegsForm: {},
      recruitId: "",
      areaDateList: [],
      sendMessageType: "",
      sendMessage: {
        // type: "",
        // volunteerIds: "",
        // ids: "",
      },
      admissionTimesData: [],
      btnLoading: false,
      sendMessageRules: {},
      volunteerId: "",
      editorOption: {
        placeholder: "暂无内容......"
      },
      dialogLeaderTitle: "",
      leaderRules: {
        // timeIds: [
        //   {
        //     type: "array",
        //     required: true,
        //     message: "请至少选择一个时间",
        //     trigger: "change"
        //   }
        // ]
      },
      leaderForm: {
        timeIds: []
      },
      leaderDialogVisible: false,
      timeAddress: [],
      showAllSelect: true,
      leaderId: "",
      number:0,
    };
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (!val) {
          this.$nextTick(() => {
            this.volunteerId = "";
            this.messegsForm = {};
            this.$refs["messegsForm"].clearValidate();
            this.getRecruitApplyList();
          });
        }
      },
      deep: true
    },
    beforeRouteLeave(to,from,next){
      to.meta.isAlive = true
      next()
    },
    leaderDialogVisible: {
      handler(val) {
        if (!val) {
          this.$nextTick(() => {
            this.leaderForm.timeIds = [];
            this.$refs["leaderForm"].clearValidate();
          });
        }
      },
      deep: true
    }
  },
  async mounted() {
    this.recruitId = this.$route.query.recruitId;
    await this.getRecruitDetail();
    this.getRecruitApplyList();
  },
  methods: {
    //获取报名信息分页数据
    currentChange(val) {
      this.pageIndex = val;
      this.getRecruitApplyList();
    },
    applyChecked(e, scope) {
      scope.row.checked = e;
      let memberObj = JSON.parse(JSON.stringify(this.memberData));
      let checkedArr = memberObj.filter(
        item => item.checked == true && item.disable == false
      );
      this.number  = checkedArr.length
      console.log(e);
      if(memberObj.length===checkedArr.length){
        this.showAllSelect = false
      }
    },
    //点击全选
    handleSelect() {
      if (this.showAllSelect === true) {
        this.showAllSelect = false;
        this.memberData.forEach(el => {
          el.checked = true;
        });
      } else {
        this.showAllSelect = true;
        this.memberData.forEach(el => {
          if (el.disable === false) {
            el.checked = false;
          }
        });
      }
      let memberObj = JSON.parse(JSON.stringify(this.memberData));
      let checkedArr = memberObj.filter(
        item => item.checked == true && item.disable == false
      );
      this.number  = checkedArr.length
    },
    dateFormatNew(intDate) {
      if (intDate) {
        return intDate.replace(" 00:00:00", "");
      }
    },
    dateFormat(intDate) {
      if (intDate) {
        return intDate.replace(":00", "");
      }
    },
    senDmessagePeo(type) {
      // this.$set(this.sendMessage, "type", type)
      this.sendMessageType = type;
    },
    // 报名信息表
    getRecruitApplyList() {
      let params = {
        recruitId: this.recruitDetail.id,
        activityId: this.recruitDetail.activityId,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      if (this.memberType == 2) {
        this.$set(params, "pinyin", true);
      }
      if (this.memberType == 3) {
        this.$set(params, "creditScore", true);
      }
      listRecruitApply(params).then(res => {
        if (res.code == 0) {
          if (res.data && res.data.length > 0) {
            res.data.forEach(item => {
              if (item.sendTime) {
                this.$set(item, "checked", true);
                this.$set(item, "disable", true);
              } else {
                this.$set(item, "checked", false);
                this.$set(item, "disable", false);
              }
            });
            this.memberData = res.data;
            this.totalCount = res.page.totalCount*1
            // this.memberData = res.data.map(item => {
            // 	return {
            // 		...item,
            // 		checked: false
            // 	}
            // })
          } else {
            this.memberData = [];
          }
        }
      });
    },
    changeRecruitFn(type) {
      switch (type) {
        case "open":
          this.$confirm("确定开启此招募吗?", "开启招募", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              changeRecruit({
                id: this.recruitId,
                status: "PROCESSING"
              }).then(res => {
                if (res.code == 0) {
                  this.$message({
                    type: "success",
                    message: "开启成功!"
                  });
                }
              });
            })
            .catch(() => {});
          break;
        case "close":
          this.$confirm("确定关闭此招募吗?", "关闭招募", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              changeRecruit({
                id: this.recruitId,
                status: "FINISHED"
              }).then(res => {
                if (res.code == 0) {
                  this.$message({
                    type: "success",
                    message: "关闭成功!"
                  });
                }
              });
            })
            .catch(() => {});
          break;
      }
    },
    editRecruit() {
      // console.log(this.recruitId)
      // let routeData = this.$router.resolve({
      //   path:"/recruitList/edit",
      //   query:{recruitId:this.recruitId}
      // })
      // window.open(routeData.href,"_blank")
      this.$router.push({
        path: `/recruitList/edit?recruitId=${this.recruitId}`
      });
    },
    getRecruitDetail() {
      return new Promise((resolve, reject) => {
        detailRecruit(this.recruitId, {
          id: this.recruitId
        }).then(res => {
          if (res.code == 0) {
            if (res.data.recruitTimes && res.data.recruitTimes.length > 0) {
              let timeAddress = [];
              res.data.recruitTimes.forEach(item => {
                timeAddress.push([
                  {
                    timeStr: item.timeStr,
                    starTime: item.starTime,
                    endTime: item.endTime,
                    address: res.data.recruitAddress,
                    signNum: res.data.signNum,
                    signUpNum: item.signUpNum
                  }
                ]);
                this.$set(res.data, "recruitTimeAddress", timeAddress);
              });
            } else {
              this.$set(res.data, "recruitTimeAddress", []);
            }
            this.recruitDetail = res.data;
            console.log("recruitDetail", this.recruitDetail);
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    getEnableSetLeader() {
      return new Promise((resolve, reject) => {
        detailRecruit(this.recruitId, {
          id: this.recruitId
        }).then(res => {
          if (res.code == 0) {
            this.recruitDetail.leader = res.data.leader;
            resolve();
          } else {
            reject();
          }
        });
      });
    },
    editorFocus(e) {
      e.enable(false);
    },
    toTimeSheet() {
      this.$router.push({
        path: `/timeSheet?activityId=${
          this.recruitDetail.activityId
        }&&activityName=${this.recruitDetail.activityName}&&recruitId=${
          this.recruitDetail.id
        }`
      });
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    closeLeaderDialog() {
      this.leaderDialogVisible = false;
    },
    submitLeaderDialog() {
      this.$refs["leaderForm"].validate(valid => {
        if (valid) {
          console.log(this.leaderForm);
          var params = {
            timeIds: this.leaderForm.timeIds
          };
          signUpInformation(this.leaderId, params).then(res => {
            this.$message({
              type: "success",
              message: "领队修改成功"
            });
            this.leaderDialogVisible = false;
          });
        }
      });
    },
    submitDialog() {
      if (
        this.dialogTitle == "录取通知发送" ||
        this.dialogTitle == "替补通知发送"
      ) {
        let params = JSON.parse(JSON.stringify(this.sendMessage));
        let messageText = "";
        if (params.admissionStatus == 2) {
          if (params.admissionTimes.length > 0) {
            params.admissionTimes = params.admissionTimes.map(item => {
              return {
                starTime: item.split("至")[0],
                endTime: item.split("至")[1]
              };
            });
          }
          messageText = "录取通知发送成功!";
        } else {
          delete params.admissionTimes;
          messageText = "替补通知发送成功!";
        }
        console.log("submitDialog", params);
        this.btnLoading = true;
        sendApplyedMessage(params)
          .then(res => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: messageText
              });
              this.sendMessage = {};
              this.admissionTimesData = [];
              this.sendMessageType = "";
              this.dialogVisible = false;
              this.btnLoading = false;
              this.getRecruitApplyList();
            } else {
              this.btnLoading = false;
            }
          })
          .catch(err => {
            // console.log("err", err);
            this.sendMessage = {};
            this.admissionTimesData = [];
            this.sendMessageType = "";
            this.dialogVisible = false;
            this.btnLoading = false;
          });
      } else {
        this.$refs["messegsForm"].validate(valid => {
          if (valid) {
            let params = {
              ...this.messegsForm,
              category: "私聊",
              sender: this.recruitDetail.id,
              receiver: this.volunteerId
            };
            chatPrivate(params).then(res => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "发送成功!"
                });
                this.dialogVisible = false;
              }
            });
          }
        });
      }
    },
    actionFn(type, scope) {
      switch (type) {
        case "submit":
          this.dialogTitle = `私聊消息发送（${scope.row.volunteer.name}）`;
          this.volunteerId = scope.row.volunteerId;
          this.dialogVisible = true;
          break;
        case "leader":
          console.log(scope);
          listTimesLeader({ id: scope.row.id }).then(res => {
            res.data.forEach(el => {
              if (el.activityLeader === true) {
                this.leaderForm.timeIds.push(el.id);
              }
            });
            this.timeAddress = res.data;
          });
          this.dialogLeaderTitle = `活动领队设置（${
            scope.row.volunteer.name
          }）`;
          this.leaderDialogVisible = true;
          this.leaderId = scope.row.id;
          // let confirmText = ""
          // let confirmTitle = ""
          // let messageText = ""
          // let isLeader = undefined
          // if (scope.row.activityLeader) {
          // 	confirmText = "确定取消敌人的领队吗?"
          // 	confirmTitle = "取消领队"
          // 	messageText = "取消领队成功!"
          // 	isLeader = false
          // } else {
          // 	confirmText = "确定将此人设为领队吗?"
          // 	confirmTitle = "设为领队"
          // 	messageText = "设置领队成功!"
          // 	isLeader = true
          // }
          // this.$confirm('确定将此人设为领队吗?', '设为领队', {
          // 	confirmButtonText: '确定',
          // 	cancelButtonText: '取消',
          // 	type: 'warning'
          // }).then(() => {
          // 	let params = JSON.parse(JSON.stringify(scope.row))
          // 	params.activityLeader = isLeader
          // 	console.log("params", params)
          // 	editApplyed(params.id, params).then(async (res) => {
          // 		if (res.code == 0) {
          // 			this.$message({
          // 				type: 'success',
          // 				message: messageText
          // 			});
          // 			this.getRecruitApplyList()
          // 		}
          // 	})
          // }).catch(() => {});
          break;
      }
    },
    downLoadApply() {
      let params = {
        recruitId: this.recruitDetail.id
      };
      downloadApplyList(params).then(res => {
        console.log("downLoadApply", res);
        const blob = new Blob([res], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        // const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = "报名信息.xlsx";
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, "报名信息.xlsx");
        }
      });
    },
    getPhoneNum(intStr) {
      if (intStr) {
        var regx = /(1[3|4|5|7|8][\d]{9}|0[\d]{2,3}-[\d]{7,8}|400[-]?[\d]{3}[-]?[\d]{4})/g;
        var phoneNums = intStr.match(regx);
        if (phoneNums) {
          for (var i = 0; i < phoneNums.length; i++) {
            var temp = phoneNums[i];
            // intStr = intStr.replace(phoneNums[i], '<a href="tel:' + temp +
            // 	'"  class="copy phone-num" style=" text-decoration: underline;color: #2878FF;">' +
            // 	temp + '</a>');
          }
        }
        return temp;
      } else {
        return "";
      }
    },
    resetMessageFn() {
      // this.sendMessageType = ""
      this.getRecruitApplyList();
    },
    sendMessageFn() {
      let memberObj = JSON.parse(JSON.stringify(this.memberData));
      let mapArr = memberObj.filter(
        item => item.checked == true && item.disable == false
      );
      let volunteerArr = [];
      let idsArr = [];
      if (mapArr && mapArr.length > 0) {
        idsArr = mapArr.map(item => {
          return item.id;
        });
        volunteerArr = mapArr.map(item => {
          return item.volunteerId;
        });
      }
      if (volunteerArr.length > 0 && idsArr.length > 0) {
        this.sendMessage = {};
        this.admissionTimesData = [];
        let phoneNum = this.getPhoneNum(this.recruitDetail.contact);
        let params = {
          volunteerIds: volunteerArr,
          ids: idsArr,
          contactPhone: phoneNum,
          // contact: this.recruitDetail.contact,
          admissionStatus: this.sendMessageType,
          activityName: this.recruitDetail.activityName,
          organizer: this.recruitDetail.organizer,
          recruitId: this.recruitDetail.id,
          contact: "",
          qqGroupNumber: "",
          admissionTimes: []
        };
        // console.log("params", params, phoneNum);
        this.sendMessage = params;
        if (params.admissionStatus == 2) {
          if (
            this.recruitDetail.recruitTimeAddress &&
            this.recruitDetail.recruitTimeAddress.length > 0
          ) {
            this.recruitDetail.recruitTimeAddress.forEach((item, index) => {
              item.forEach((ite, ind) => {
                this.admissionTimesData.push({
                  value: `${index}_${ind}`,
                  label: `${ite.starTime}至${ite.endTime}`
                });
              });
            });
          }
          //   console.log("录取通知", this.admissionTimesData);
          this.dialogTitle = "录取通知发送";
          this.dialogVisible = true;
        } else if (params.admissionStatus == 3) {
          this.dialogTitle = "替补通知发送";
          this.dialogVisible = true;
        } else {
          this.$confirm("确定将不录取这些人员吗?", "不录取人员", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
            .then(() => {
              sendApplyedMessage(params).then(res => {
                if (res.code == 0) {
                  this.$message({
                    type: "success",
                    message: "不录取人员成功~"
                  });
                  this.sendMessage = {};
                  this.admissionTimesData = [];
                  this.sendMessageType = "";
                  this.getRecruitApplyList();
                }
              });
            })
            .catch(() => {});
        }
      } else {
        this.$message({
          type: "error",
          message: "请选择人员!"
        });
      }
    },
    memberTypeFn(type, item) {
      switch (type) {
        case "show":
          this.memberTypeShow = !this.memberTypeShow;
          break;
        case "select":
          this.memberType = item.type;
          this.getRecruitApplyList();
          this.memberTypeShow = false;
          break;
      }
    },
    memberTypeMap(type) {
      let mapObj = {
        1: "按报名时间排序",
        2: "按姓名字母排序",
        3: "按信誉分排序"
      };
      return mapObj[type];
    },
    statusMap(status) {
      let mapObj = {
        0: "未发送",
        1: "已发送未阅读",
        2: "已阅读未确认",
        3: "已确认收到"
      };
      return mapObj[status];
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/.el-input__inner {
  height: 36px;
  border-radius: 0px 2px 2px 0px;
  border: 0;
}

/deep/.common-table-row .el-textarea__inner {
  resize: none;
  height: 400px;
  border: 0;
}

/deep/.recruitDetail-dia-textarea .el-textarea__inner {
  height: 120px !important;
  resize: none;
  border: 0;
}

/deep/.recruitDetail-apply .el-table {
  border-right: 0 !important;
}
// /deep/.el-checkbox__inner{
// 	border-radius:50%;
// }
/deep/.recruitDetail-inform .el-button--default {
  color: #606266 !important;
  border: 1px solid #dcdfe6 !important;
}

/deep/.el-dialog__body {
	max-height: 500px;
	overflow-y: auto;
}
.all-select {
  font-size: 14px;
  color: #909399;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
}
.select-icon {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}
.flex-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.table-action {
  .table-action-status0 {
    color: #303133;
  }

  .table-action-status1 {
    color: #e63757;
  }

  .table-action-status2 {
    color: #f5803e;
  }

  .table-action-status3 {
    color: #00b96b;
  }
}

.recruitDetail {
  .recruitDetail-content {
    padding: 0 20px 20px 20px;

    .recruitDetail-btn {
      margin: 10px 0;
      display: flex;
      align-items: center;

      .recruitDetail-btn-disable {
        width: 124px;
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #b4bcc6;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #b4bcc6;
        cursor: not-allowed;
        margin-left: 10px;
      }

      .recruitDetail-select {
        position: relative;
        margin-right: 16px;

        .recruitDetail-select-val {
          width: 120px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          span {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 20px;
            color: #303133;
          }

          img {
            width: 14px;
            height: 14px;
            margin-left: 8px;
          }
        }

        .recruitDetail-select-option {
          width: 120px;
          height: 75px;
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
          border-radius: 2px;
          position: absolute;
          display: flex;
          flex-direction: column;
          left: 0;
          top: 24px;
          z-index: 200;
          padding: 8px 8px 4px 8px;
          box-sizing: border-box;

          span {
            flex: 1;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 17px;
            color: #303133;
            margin: 0 6px 4px 0;
          }

          img {
            width: 14px;
            height: 14px;
            margin-bottom: 4px;
          }
        }

        .select-option-show {
          transform: rotateX(180deg);
        }

        .select-option-select {
          color: $theme-color !important;
        }
      }
    }

    .recruitDetail-inform {
      margin-top: 10px;
    }

    .recruitDetail-apply {
      margin: 8px 0 20px 0;

      .recruitDetail-date {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        line-height: 20px;
        color: #606266;
        margin: 10px 0;
      }
    }

    .recruitDetail-title {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: bold;
      line-height: 22px;
      color: #1c2233;
    }
  }

  .recruitDetail-dia-formitem {
    margin: 20px 20px 0 0;

    .recruitDetail-dia-ipt {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
    }

    .recruitDetail-dia-textarea {
      border: 1px solid #dcdfe6;
      border-radius: 4px;
    }
  }
}
</style>
